import React from "react"
import showdown from "showdown"
import * as newsStyles from "./news.module.scss"

export default function News(props) {
  const converter = new showdown.Converter({'simpleLineBreaks': true})

  return (
      <div id={props.title.replace(/\s+/g, '-').toLowerCase()} className={`${[newsStyles.container, "animate"].join(" ")}`}>
        <h2 className={`${[newsStyles.title, "animate"].join(" ")}`}>{props.title}</h2>
        <div className={newsStyles.content}>
          {props.newsItems.map((item, i) => {
            return (
              <div className={`${[newsStyles.item, "animate"].join(" ")}`} key={i}>
                <h4 className={`${[newsStyles.itemTitle, "animate"].join(" ")}`}>{item.title}</h4>
                <div className={`${[newsStyles.description, "animate"].join(" ")}`} dangerouslySetInnerHTML={{__html: converter.makeHtml(item.content)}}></div>
              </div>
            )
          })}
        </div>
    </div>
  )
}
import React from "react"
import * as quoteStyles from "./quote.module.scss"

export default function Quote(props) {
  return (
    <div className={quoteStyles.container}>
        <div className={quoteStyles.content}>
            <svg className={`${[quoteStyles.icon, "animate"].join(" ")}`} viewBox="0 0 39 31">
              <path d="M15.24.132c1.385.346 2.284 1.453 2.7 3.321-2.007 1.108-4.049 2.769-6.125 4.983-2.076 2.215-3.53 4.637-4.36 7.267h.311c2.63 0 4.689.674 6.177 2.024 1.488 1.35 2.232 3.097 2.232 5.242 0 2.007-.744 3.702-2.232 5.086-1.488 1.385-3.27 2.077-5.346 2.077-2.491 0-4.464-.883-5.917-2.647C1.227 25.72.5 23.592.5 21.1c0-4.221 1.35-7.993 4.048-11.314 2.7-3.322 6.263-6.54 10.692-9.654zm20.762 0c1.384.346 2.283 1.453 2.699 3.321-2.007 1.108-4.049 2.769-6.125 4.983-2.076 2.215-3.53 4.637-4.36 7.267h.312c2.63 0 4.688.674 6.176 2.024 1.488 1.35 2.232 3.097 2.232 5.242 0 2.007-.744 3.702-2.232 5.086-1.488 1.385-3.27 2.077-5.346 2.077-2.491 0-4.464-.883-5.917-2.647-1.453-1.765-2.18-3.893-2.18-6.385 0-4.221 1.35-7.993 4.049-11.314 2.699-3.322 6.263-6.54 10.692-9.654z"></path>
            </svg>
            <p className={`${[quoteStyles.text, "animate"].join(" ")}`}>{props.text}</p>
            <div className={`${[quoteStyles.caption, "animate"].join(" ")}`}>
                <div>
                    <p className={quoteStyles.subHeading}>{props.subHeading}</p>
                    <p className={quoteStyles.description}>{props.description}</p>
                </div>
            </div>
        </div>
    </div>
  )
}
import React, { useState, useEffect, useRef } from "react"
import Image from "../components/image"
import * as cardStyles from "./card.module.scss"

export default function Card(props) {
   const [height, setHeight] = useState(0)
   const ref = useRef(null)

   useEffect(() => {
      function handleResize() {
         setHeight(ref.current.clientHeight)
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
   })

  return (
   <div id={props.id} className={cardStyles.container}>
      <h2 className={`${[cardStyles.title, "animate"].join(" ")}`}>{props.title}</h2>
      <div className={(props.reversed ? `${[cardStyles.card, cardStyles.reversed].join(" ")}` : `${cardStyles.card}`)} style={{minHeight: `${(height + 192) + 'px'}`}}>
         <div className={`${[cardStyles.image, "animate"].join(" ")}`} style={{aspectRatio: `${props.ratio}`}}>
            <Image filename={props.image} alt={props.image} />
         </div>
         <div className={`${[cardStyles.content, "animate"].join(" ")}`} ref={ref}>
            <div>
               <div className={cardStyles.inner}>
                  <h3 className={`${[cardStyles.cardTitle, "animate"].join(" ")}`}>{props.cardTitle}</h3>
                  <div className={`${[cardStyles.text, "animate"].join(" ")}`} dangerouslySetInnerHTML={{__html: props.text}}></div>
               </div>
            </div>
         </div>
      </div>
   </div>
  )
}
// extracted by mini-css-extract-plugin
export var card = "card-module--card--ECuRh";
export var cardTitle = "card-module--card-title--ttOYc";
export var container = "card-module--container--rgAfu";
export var content = "card-module--content--GmHd+";
export var image = "card-module--image--ODSgJ";
export var inner = "card-module--inner--ydUfS";
export var reversed = "card-module--reversed--mQN6+";
export var subHeading = "card-module--sub-heading--YVgMI";
export var text = "card-module--text--2pzeY";
export var title = "card-module--title--n8Mls";
import React from "react"
import * as introStyles from "./intro.module.scss"
import Separator from "../components/separator"

export default function Intro(props) {
  return (
    <div className={introStyles.container}>
        <div className={introStyles.content}>
            <h5 className={`${[introStyles.subHeading, "animate"].join(" ")}`}>{props.subHeading}</h5>
            <p className={`${[introStyles.title, "animate"].join(" ")}`}>{props.title}</p>
        </div>
        <Separator />
    </div>
  )
}
import React from "react"
import Separator from "../components/separator"
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import * as statsStyles from "./stats.module.scss"

export default function Stats(props) {
    const [focus, setFocus] = React.useState(false);

    return (
    <div className={statsStyles.container}>
        <div className={statsStyles.content}>
            {props.items.map((item, i) => {
                return (
                    <div className={`${[statsStyles.item, "animate"].join(" ")}`} key={i}>
                        <div className={statsStyles.number}>
                            <CountUp start={focus ? 0 : null} end={item.number} duration={2} redraw={true}>
                                {({ countUpRef }) => (
                                    <VisibilitySensor onChange={(isVisible) => {if (isVisible)
                        {setFocus(true);}}} delayedCall={true}>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            {item.description && <span className={statsStyles.unit}>{item.description}</span>}
                        </div>
                        <p className={statsStyles.description}>{item.subtitle}</p>
                    </div>
                )
            })}
        </div>
        <Separator />
    </div>
  )
}
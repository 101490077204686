import React, { useEffect } from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as separatorStyles from "./separator.module.scss"

export default function Separator() {
  function animateHeight(elem) {
    gsap.to(
      elem,
      {
        height: 140,
        ease: "power3.easeOut",
        duration: 1
      }
    )

    elem.classList.remove("animateHeight")
  }

  function hide(elem) {
    gsap.set(elem, {height: 0});
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.utils.toArray(".animateHeight").forEach(function(elem) {
      hide(elem);

      ScrollTrigger.create({
        trigger: elem,
        start: "top 90%",
        onEnter: function() { animateHeight(elem) },
      });
    });
  })

  return (
    <div className={separatorStyles.container}>
      <span className={`${[separatorStyles.stroke, "animateHeight"].join(" ")}`}></span>
    </div>
  )
}